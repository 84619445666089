import revive_payload_client_u5TsffZJFi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sG6LczA3zf from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iSihfy0fHw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ngOsaPAFZf from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cpwEayMyWg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HzFXXJn4KK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_91Lo8GVSYm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ov3qk9R1Et from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_nrnwmpwrxnfkw7dfj6t5dai57e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_u5TsffZJFi,
  unhead_sG6LczA3zf,
  router_iSihfy0fHw,
  payload_client_ngOsaPAFZf,
  navigation_repaint_client_cpwEayMyWg,
  check_outdated_build_client_HzFXXJn4KK,
  chunk_reload_client_91Lo8GVSYm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ov3qk9R1Et,
  vue_query_wrmMkNpEpe
]